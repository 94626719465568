.page {
    margin-top: 13px;
    h1.page-title {
        font-weight: 300;
        color: $primary-color;
        font-size: 3rem;
        line-height: 1;
    }
}
.page-section {
    padding: 40px 0;
    &.dark {
        color: #fff;
    }
    .servizi {
        padding: 20px;
        background-color: #626469;
    }
    .icon {
        padding: 20px;
        margin-bottom: 20px;
        .img {
            height: 60px;
            text-align: center;
        }
        .icon-text {
            text-align: center;
            font-weight: bold;
        }
    }
    .page-image {
        margin-bottom: 20px;
    }
}

.page-thumbnail {
    height: 400px;
    background-size: cover;
    background-position: center center;
}
.servizio {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 400px;
    display:flex;
    .wrapper {
        padding: 20px;
        background-color: rgba(black, .5);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        text-align: center;
        img {
            height: 30px;
        }
        .title {
            text-transform: lowercase;
            font-size: 2rem;
            position:relative;
            padding-bottom: 15px;
            margin-bottom: 5px;
            line-height: 1;
            &:after {
                content: '';
                width: 40px;
                border-bottom: 1px solid white;
                position:absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
.filter-menu {
    margin-bottom: 40px;
}
#map {
    height: 500px;
}

@include breakpoint ( large up) {
    .page {
        margin-top: 13px;
        h1.page-title {
            font-weight: 300;
            color: $primary-color;
            font-size: 7rem;
            text-align: left;
        }
    }
}