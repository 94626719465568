#main-menu {
    position:fixed;
    background-color: #88898d;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display:none;
    /*transform: translateX(100%);*/
    z-index: 100;
    h2 {
        margin-top: 10px;
        line-height: 0;
    }
    ul {
        li {
            opacity:0;
            transform: translate3d(-10%, 0, 0);
            a {
                color: #D5D5D5;
                font-weight: 300;
                padding:5px 10px;
                line-height: 0.9;
                display:inline-block;
                &:hover {
                    color: #232323;
                }
            }
            &.active {
                > a {
                    color: #232323;
                    background-color: transparent;
                }
            }
        }
        &.nav-menu {
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX(-50%);
            text-transform: lowercase;
            margin-top: 40px;
            li {
                text-align: center;
                a {
                    font-size: 2rem;
                    line-height: 1.5;
                }
                &[aria-expanded="true"] {
                    > a {
                        color: #232323;
                    }
                }
                ul.submenu {
                    li {
                        a {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
        &.social-menu {
            position: absolute;
            bottom: 120px;
            left: 10%;
            li {
                a {
                    font-size: 2vw;
                }
            }
        }
        &.secondary-menu {
            position:absolute;
            bottom: 20px;
            left: 10%;
            li {
                position:relative;
                &:after {
                    content: '/';
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    color:#D5D5D5;
                }
                &:last-child:after {
                    content: '';
                }
                a {
                    margin-right: 16px;
                }
            }
        }
    }
    // display:none;
}

@include breakpoint ( large up ) {
    #main-menu {
        ul.nav-menu {
            left: calc(50% - 50px);
            li {
                a {
                    font-size: 2.5vw;
                }
                ul.submenu {
                    li {
                        a {
                            font-size: 1.5vw;
                        }
                    }
                }
            }
        }
        h2 {
            margin-top: 0;
        }
    }
}