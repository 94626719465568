.block-wrapper {
    .button-like {
        text-align: left;
        a {
            color: #848484;
            font-weight:bold;
            text-decoration: underline;
            &:after {
                content: '>';
            }
        }
    }
}
.block-title {
    font-weight:300;
    margin-top: 10px;
}
.block-subtitle {
    h4 {
        /*margin-left: 25px;*/
        &:before {
            content: '_';
        }
        font-weight:bold;
        font-size: 1rem;
        /*.anno {
            margin-right: 5px;
        }*/
        /*.type {
            margin-left:5px;
        }*/
    }
}
.overlay-wrapper {
    position:relative;
    .overlay {
        position:absolute;
        top: 30px;
        left: 30px;
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        background-color: rgba(white, .7);
        visiiblity:hidden;
        opacity:0;
        transition: opacity .6s ease, visibility 0s .6s;
    }
    &:hover {
        .overlay {
            visibility: visible;
            opacity:1;
            transition: opacity .6s ease, visibility 0s;
        }
    }
}