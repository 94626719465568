#single-content {

}
#descrizione-foto {
    padding: 60px 0 30px 0;
    font-size: 1.5vw;
    p {
        line-height: 1.5;
    }
}
#galleria {
    .gallery-layout {
        padding: 30px 0;
        img {
            width: 100%;
        }
    }
}