@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'node_modules/font-awesome/scss/font-awesome.scss';
@import 'hamburgers/_sass/hamburgers/hamburgers.scss';

@import 'home-block';
@import 'static';
@import 'page-thumb';
@import 'single-content';
@import 'menu';
@import 'archivio';
@import 'sidebar';
@import 'page';

.hamburger.is-active .hamburger-inner {
    /*background: transparent;
    transition: background .3s ease .1s;*/
    /* &:before,
     &:after {
         opacity: 1 !important;
     }*/
}

.with-margin-bottom {
    margin-bottom: 40px;
}

body#homepage {
    width: 100%;
    height: 100%;
    /* background-color: #e1e1e1;*/ //FOR DEBUG
}

header.header {
    background-color: white;
    padding: 20px 70px 20px 20px;
    h1, h2 {
        margin-top: 10px;
        margin-bottom: 0;
        line-height: 0;
        img {
            max-width: 200px;
        }
    }
    ul.menu.social {
        li:first-child a {
            padding-left: 0;
        }
        a {
            color: #9d9c9c;
        }
        &.language {
            li:first-child {
                a {
                    border-right: 1px solid #e1e1e1;
                }
            }
            a {
                padding-top: 5px;
                padding-bottom: 5px;
            }
            .language-active {
                font-weight: bold;
            }
        }
    }
}
.header-menu {
    @extend header.header;
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: #88898d !important;
    img {
        max-width: 200px;
    }
    ul.menu.social {
        /*z-index: 1;*/
        li {
            opacity: 1 !important;
            a {
                color: white !important;
            }
        }
    }
}
.language-switcher {
    /*position: fixed;
    bottom: 10%;
    left: 10%;
    width: 40%;
    z-index: 99999;*/
}

.col {
    width: calc(50% - 50px);
    position:fixed;
    top: 0;
    left: 0;
    &.right-col {
        right: 100px;
        left:auto;
        top:auto;
        bottom:0;

    }
}

.block-wrapper {
    padding: 20px 70px 20px 20px;
    .img-wrapper {
        position: relative;

    }
}


.menu-toggle {
    fill:none;
    stroke:#262e36;
    stroke-linecap:round;
    stroke-miterlimit:10;
    stroke-width:2px;
}
#menu-toggle {
    /*width: 20px;*/
    position:absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50% );
    z-index: 99;
    cursor:pointer;
}

#language-logo {
    position:absolute;
    bottom: 20px;
    left: 50%;
    width: 500px;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: 0 0;
    .logo-biesse {
        float:left;
    }
    .language {
        position:relative;
        left: 60px;
        ul.menu li {
            position:relative;
            a {
                padding: 5px 10px;
                color: #D5D5D5;
            }
            &.active a {
                font-weight: bold;
                text-transform: uppercase;
                color: #666;
            }
            &:first-child:after {
                content: '/';
                position:absolute;
                top: 50%;
                right: -2px;
                transform: translateY(-50%);
                font-weight: bold;
                color: #D5D5D5;
            }
        }
    }
}

#footer {
    text-align: center;
    position:relative;
    width: 100%;
    /*height: 100px;*/
    bottom: 0;
    left: 0;
    background-color: rgba(210, 207, 210, 0.9);
    line-height: .8;
    z-index: 201;
    padding: 20px;
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    .testi {
        color: #1F252B;
        padding-left: 100px;
    }
    .social {
        font-family: 'Lato', sans-serif;
        ul {
            margin-left: 0;
            list-style: none;
            li {
                line-height: 1;
                a {
                    color: $black;
                }
                &:first-child a {
                    padding-left: 0;
                }
            }
        }
    }
    .logo-footer {
        position:absolute;
        bottom: 10%;
        right:10%;
        img {
            max-width: 50%;
        }
    }
    p.info {
        a {
            color: $black;
        }
    }
    p.credits {
        text-align: center;
        font-size: .8rem;
        a {
            color: $black;
            text-transform: uppercase;
        }
    }
}

@include breakpoint ( large up) {
    .block-wrapper {
        padding: 100px;
    }

    header.header {
        position:fixed;
        top: 0;
        left: 0;
        width:calc(100% - 100px);
        z-index: 1;
        background-color: white;
        padding: 30px 0;
        h1, h2 {
            margin-bottom: 0;
            line-height: 0;
            margin-top: 0;
            img {
                max-width: none;
            }
        }
        ul.menu.social {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 100px;
            li:first-child a {
                padding-left: 0;
            }
            a {
                color: #9d9c9c;
            }
            &.language {
                li:first-child {
                    a {
                        border-right: 1px solid #e1e1e1;
                    }
                }
                a {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
                .language-active {
                    font-weight: bold;
                }
            }
        }
    }

    .header-menu {
        img {
            max-width: none;
        }
    }

    #footer {
        text-align: left;
        position:fixed;
        width: 100%;
        height: 100px;
        bottom: 0;
        left: 0;
        background-color: rgba(210, 207, 210, 0.9);
        line-height: .8;
        z-index: 10;
        padding: 25px 140px 0 100px;
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        .testi {
            color: #1F252B;
            padding-left: 100px;
        }
        .social {
            font-family: 'Lato', sans-serif;
            ul {
                margin-left: 0;
                list-style: none;
                li {
                    line-height: 1;
                    a {
                        color: $black;
                    }
                    &:first-child a {
                        padding-left: 0;
                    }
                }
            }
        }
        .logo-footer {
            position:absolute;
            bottom: 10%;
            right:10%;
            img {
                max-width: 50%;
            }
        }
        p.info {
            a {
                color: $black;
            }
        }
        p.credits {
            text-align: right;
            padding-right: 1rem;
            a {
                color: $black;
                text-transform: uppercase;
            }
        }
    }
}