#sidebar {
    position:fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background-color: #88898d;
    z-index: 200;
    .logo {
        position:absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
}
#payoff {
    position:absolute;
    top: 150px;
    left: 18%;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: 0 0;
    width: 200px;
    font-size: 1.2em;
    color: white;
}
@include breakpoint ( large up) {
    #sidebar {
        width: 100px;
    }
    #payoff {
        position:absolute;
        top: 150px;
        left: 37%;
        transform: rotate(-90deg) translateX(-50%);
        transform-origin: 0 0;
        width: 200px;
        font-size: 1.2em;
        color: white;
    }
}