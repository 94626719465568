$page-thumb-height: 500px;
#page-thumb {
    height: $page-thumb-height;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    iframe {
        width: 100%;
        height: $page-thumb-height;
    }
}