#static {
    #layout-content {
        padding: 10px 60px 10px 10px;
    }
    /*.block-title {
        margin-left: 25px;
    }*/
    .block-subtitle {
        margin-bottom: 20px;
    }
    @include breakpoint ( large up) {
        #layout-content {
            padding: 100px;
        }
    }
}